<template>
  <div class="yt-main">
    <div class="yt-breadcrumb-wrapper">
      <el-breadcrumb class="yt-breadcrumb">
        <el-breadcrumb-item>专业管理</el-breadcrumb-item>
        <el-breadcrumb-item to="/manage/post/list">专业模型</el-breadcrumb-item>
        <el-breadcrumb-item>查看详情</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="yt-container">
      <div class="yt-content" style="height: auto; padding-bottom: 20px">
        <p class="title">{{ postInfo.postName }}专业模型详情总览</p>
        <div class="description-wrapper" style="margin-bottom: 20px;">
          <span style="flex-shrink: 0;margin-top: 10px">模型简介：</span>
          <UpdateInput :updateData="postInfo.postDescription" type="textarea" :postType="postType" :maxLength="300" @setValue="updatePost">
            <el-tooltip
              effect="dark"
              :disabled="postInfo.postDescription === null || postInfo.postDescription === '' || postInfo.postDescription.length < 200"
              :content="postInfo.postDescription"
              placement="top"
            >
              <p class="description two-line-ellipsis" style="margin-top: 10px">
                {{ postInfo.postDescription === null || postInfo.postDescription === '' ? '暂无模型简介' : postInfo.postDescription }}
              </p>
            </el-tooltip>
          </UpdateInput>
        </div>
        <template v-if="!isMainSkillsEmpty">
          <div ref="sankeyChart" style="width: 100%; min-height: 370px"></div>
        </template>
        <template v-if="isMainSkillsEmpty && !postInfoLoading">
          <div class="sankey-empty">
            <YTIcon :href="'#icon-zanwushuju'" style="font-size: 180px" />
            <p>暂无数据</p>
          </div>
        </template>
      </div>
      <div class="yt-content before-down" style="height: auto; margin-top: 10px">
        <ul class="main-skill-list">
          <li
            v-for="mainSkill in mainSkillList"
            :class="{ active: mainSkill.postSkillId === activeMainSkillId }"
            @click="checkSkill(mainSkill.postSkillId)"
            :key="mainSkill.postSkillId"
            class="item"
          >
            <span>{{ mainSkill.postSkillName }}</span>
            <i v-if="postType !== 2" @click="toDeleteSkill(mainSkill)" class="el-icon-error" style="margin-left: 6px"></i>
          </li>
          <li v-if="postType !== 2" class="create-button">
            <YTIcon :href="'#icon-xinzeng'" @click="toAddSkill" />
          </li>
        </ul>
        <div class="description-wrapper" style="min-height: 40px">
          <span style="flex-shrink: 0; margin-top: 10px">技能描述：</span>
          <UpdateInput
            :updateData="activeMainSkill.description"
            type="textarea"
            :maxLength="300"
            :postType="postType"
            @setValue="updateSkillDescription"
            ref="skillUpdateInput"
          >
            <el-tooltip
              effect="dark"
              :disabled="activeMainSkill.description === null || activeMainSkill.description === '' || activeMainSkill.description.length < 200"
              :content="activeMainSkill.description"
              placement="top"
            >
              <p class="description two-line-ellipsis" style="margin-top: 10px">
                {{ activeMainSkill.description === null || activeMainSkill.description === '' ? '暂无模型简介' : activeMainSkill.description }}
              </p>
            </el-tooltip>
          </UpdateInput>
        </div>
        <div class="description-wrapper">
          <div class="item" style="height: 40px">
            <span style="flex-shrink: 0;">技能权重：</span>
            <UpdateInput
              :updateData="activeMainSkill.weight"
              type="number"
              :maxLength="300"
              :postType="postType"
              @setValue="updateSkillWeight"
              style="width: auto"
              ref="skillUpdateWeightInput"
            >
              <p>
                {{ activeMainSkill.weight === null || activeMainSkill.weight === '' ? '暂无模型简介' : activeMainSkill.weight }}
              </p>
            </UpdateInput>
            <el-tooltip effect="dark" content="技能比例通过该专业下所有技能配置的权重自动计算得出" placement="top">
              <YTIcon :href="'#icon-tishi2'" style="font-size: 16px; margin-left: 6px" />
            </el-tooltip>
          </div>
          <div class="item" style="margin-top: 10px">
            <span style="flex-shrink: 0">技能比例：</span>
            <el-progress :percentage="$roundFloat(activeMainSkill.rate, 2)" style="width: 200px" color="#7CAEEF" text-inside></el-progress>
          </div>
        </div>
        <div class="match-table">
          <ul class="thead row" style="height: 40px">
            <li class="item first">评级名称</li>
            <li class="item second">要求比例</li>
            <li class="item third">级别描述</li>
            <li v-if="postType !== 2" class="item tools" style="color: #448BFF; cursor: pointer" @click="toUpdateRateList">
              <YTIcon :href="isUpdateRateList ? '#icon-baocun' : '#icon-a-bianji'" />
              {{ isUpdateRateList ? '保存' : '编辑' }}
            </li>
          </ul>
          <div v-if="rateList.length < 10 && postType !== 2" class="add-button" @click="addRate">
            <i class="el-icon-circle-plus-outline" style="margin-right: 12px; font-size: 16px" />
            添加一行评级
          </div>
          <template v-for="(item, index) in rateList">
            <ul :key="item.id" class="tbody row">
              <li class="item first" style="padding-right: 20px; flex-shrink: 0">
                <UpdateInput
                  :updateData="rateListForm[index].name"
                  type="autocomplete"
                  :maxLength="8"
                  :postType="postType"
                  :submit="false"
                  :index="index"
                  @closeUpdate="closeUpdateRateName(index)"
                  @querySearch="querySearch"
                  @change="changeName($event, index)"
                  @select="selectName($event, index)"
                  style="width: auto"
                  ref="skillUpdateRateNameInput"
                >
                  <p>
                    {{ item.name === null || item.name === '' ? '暂无名称' : item.name }}
                  </p>
                </UpdateInput>
              </li>
              <li class="item second" style="padding-right: 50px; flex-shrink: 0">
                <p style="width: 17px;">{{ item.lowScore }}</p>
                <p style="margin: 0 6px">-</p>
                <template v-if="index > 0 && index === rateList.length - 1">
                  <span>100<span style="margin-left: 6px">%</span></span>
                </template>
                <template v-else>
                  <UpdateInput
                    :updateData="rateListForm[index].highScore"
                    type="autocomplete"
                    :maxLength="3"
                    :postType="postType"
                    :submit="false"
                    :index="index"
                    @querySearch="queryHighScoreSearch"
                    @oninput="oninputNumber"
                    @change="computeScore($event, index)"
                    @select="selectScore($event, index)"
                    @closeUpdate="closeUpdateRateScore(index)"
                    style="width: auto"
                    ref="skillUpdateScoreInput"
                  >
                    <p>
                      {{ item.highScore === null || item.highScore === '' ? '' : item.highScore }}
                      <span style="margin-left: 6px">%</span>
                    </p>
                  </UpdateInput>
                </template>
              </li>
              <li class="item third" style="overflow: hidden">
                <UpdateInput
                  :updateData="rateListForm[index].description"
                  :maxLength="200"
                  :postType="postType"
                  :submit="false"
                  :index="index"
                  @change="changeDescription($event, index)"
                  @closeUpdate="closeUpdateRateDescription(index)"
                  style="width: calc(100% - 50px)"
                  ref="skillUpdateDescriptionInput"
                >
                  <el-tooltip
                    effect="dark"
                    :disabled="item.description === null || item.description === '' || item.description.length < 30"
                    :content="item.description"
                    placement="top"
                  >
                    <p class="textEllipsis">
                      {{ item.description === null || item.description === '' ? '暂无要求描述' : item.description }}
                    </p>
                  </el-tooltip>
                </UpdateInput>
              </li>
              <li class="item tools" style="text-align: center; flex-shrink: 0">
                <span v-if="index > 0 && postType !== 2" @click="deleteRate(index)" style="color: #448bff">删除</span>
              </li>
            </ul>
          </template>
        </div>
      </div>
      <div class="yt-content before-down" style="height: auto; margin-top: 10px; padding-bottom: 0">
        <p class="title" style="display: inline-block; margin-top: 6px">子技能详情</p>
        <el-button v-if="postType !== 2" type="primary" size="small" style="float: right" @click="toAddSubSkill">
          <div>
            <i class="el-icon-circle-plus-outline" />
            <p>添加子技能</p>
          </div>
        </el-button>
        <ul class="sub-skill-list">
          <li
            v-for="(subSkill, index) in subSkillList"
            :class="{ active: subSkill.isActive, 'card-active': activeCardWrapper === index }"
            :key="index"
          >
            <div class="item" :class="{ active: activeCard === index, bottom: isLastLine(index) }">
              <div class="transfer-wrapper">
                <div class="top">
                  <div>{{ subSkill.name.substring(0, 1) }}</div>
                  <p class="textEllipsis">{{ subSkill.name }}</p>
                  <span @click="toCourseList(index)">推荐课程</span>
                </div>
                <div class="row" style="margin-top: 22px">
                  <div class="row" style="width: 50%">
                    <span style="flex-shrink: 0">权重：</span>
                    <p style="color: #448BFF">{{ subSkill.weight }}</p>
                  </div>
                  <div class="row">
                    <span style="flex-shrink: 0">合格阈值：</span>
                    <p>≥{{ $roundFloat(subSkill.qualifiedRate, 0) }}%</p>
                  </div>
                </div>
                <div class="row">
                  <span style="flex-shrink: 0">比例：</span>
                  <el-progress :percentage="$roundFloat(subSkill.rate, 2)" style="width: calc(100% - 70px)" color="#7CAEEF" text-inside></el-progress>
                </div>
                <div class="row" style="align-items: flex-start">
                  <span style="flex-shrink: 0">推荐建议：</span>
                  <p class="description two-line-ellipsis">
                    {{ subSkill.unqualifiedRemark === null || subSkill.unqualifiedRemark === '' ? '暂无推荐建议' : subSkill.unqualifiedRemark }}
                  </p>
                </div>
                <div class="row" style="align-items: flex-start">
                  <span style="flex-shrink: 0">描述：</span>
                  <p class="description two-line-ellipsis">
                    {{ subSkill.description === null || subSkill.description === '' ? '暂无描述' : subSkill.description }}
                  </p>
                </div>
                <div v-if="postType !== 2" class="row button-list front">
                  <div class="has-border"><YTIcon :href="'#icon-caozuobianji'" @click="toUpdateSubSkill(subSkill)" /></div>
                  <div><YTIcon :href="'#icon-shanchu'" @click="toDeleteSubSkill(subSkill)" /></div>
                </div>
              </div>
              <div class="course-list">
                <div class="top">
                  <div>{{ subSkill.name.substring(0, 1) }}</div>
                  <p class="textEllipsis">{{ subSkill.name }}</p>
                  <span @click="toCourseList(index)">返回</span>
                </div>
                <el-tree :data="subSkill.courseList" ref="course-tree" node-key="id" icon-class="el-icon-arrow-right">
                  <span class="course-tree-node" slot-scope="{ node, data }">
                    <span>{{ node.label }}</span>
                    <span v-if="postType !== 2" class="button" @click.stop="toDeleteCourse(node, subSkill.postSkillId, index)">删除</span>
                  </span>
                </el-tree>
                <div v-if="postType !== 2" class="row button-list">
                  <div class="has-border"><YTIcon :href="'#icon-xinzeng'" @click="toConfigCourse(subSkill, index)" /></div>
                  <div>
                    <YTIcon
                      :href="activeCard === index ? '#icon-xiangshang' : '#icon-xiangxia'"
                      @click="collapseCard(index)"
                      :style="{ color: subSkill.simpleCourses.length > 0 ? '#999999' : '#CCCCCC' }"
                    />
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <PostSkillConfigDialog
      ref="mainSkillConfigDialog"
      :rateEnum="rateEnum"
      :highScoreEnum="highScoreEnum"
      @computeRate="computeRate"
      @refresh="reGetPostSkillInfo"
    />
    <ConfirmDialog ref="postSkillDeleteDialog" :title="'删除专业技能'" @on-ok="deletePostSkill">
      <div style="width: 219px; margin: 0 auto;">
        <YTIcon :href="'#icon-jingshi'" style="font-size: 18px;" />
        是否确认删除该专业技能，删除后将不可恢复
      </div>
    </ConfirmDialog>
    <PostSubSkillCUDialog ref="subSkillCUDialog" @refresh="reGetPostSkillDetail" />
    <ConfirmDialog ref="subSkillDeleteDialog" :title="'删除子技能'" @on-ok="deleteSubSkill">
      <div style="width: 219px; margin: 0 auto;">
        <YTIcon :href="'#icon-jingshi'" style="font-size: 18px;" />
        是否确认删除该子技能，删除后将不可恢复
      </div>
    </ConfirmDialog>
    <PostSkillCourseConfigDialog ref="courseConfigDialog" @refresh="insertTreeNode" />
  </div>
</template>

<script>
import YTIcon from '@components/common/YTIcon'
import UpdateInput from '@components/common/version2/UpdateInput'
import ConfirmDialog from '@components/common/dialog/ConfirmDialog'
import PostSkillConfigDialog from '@components/common/dialog/PostSkillConfigDialog'
import PostSubSkillCUDialog from '@components/common/dialog/PostSubSkillCUDialog'
import PostSkillCourseConfigDialog from '@components/common/dialog/PostSkillCourseConfigDialog'
import newPostApi from '@api/newPost'

export default {
  name: 'PostModelDetail',
  components: { YTIcon, UpdateInput, ConfirmDialog, PostSkillConfigDialog, PostSubSkillCUDialog, PostSkillCourseConfigDialog },
  data() {
    return {
      postInfo: {
        postId: '',
        postName: '',
        postDescription: '',
        mainSkills: []
      }, //岗位信息
      postInfoLoading: true,
      sankeyChart: null, //桑基图
      mainSkillList: [], //主技能
      activeMainSkillId: '', //选中主技能id
      activeMainSkill: {
        description: ''
      }, //选中主技能
      deleteSkillData: {}, //待删除主技能
      rateList: [{ id: 0, name: '', lowScore: 0, highScore: '100', description: '' }], //评级列表
      rateEnum: [{ value: '低' }, { value: '中' }, { value: '高' }, { value: '一般要求' }, { value: '中要求' }, { value: '强要求' }],
      highScoreEnum: [{ value: '5' }],
      nameError: '', //要求名称错误
      subSkillList: [], //子技能
      deleteSubSkillId: '', //待删除子技能
      activeCard: '', //下拉推荐课程
      activeCardWrapper: '', //下拉推荐课程外壳
      postType: 0, //模型类型 默认是企业
      isUpdateRateList: false, //要求评级编辑模式
      rateListForm: [{ id: 0, name: '', lowScore: 0, highScore: '100', description: '' }] //编辑模式表单
    }
  },
  computed: {
    isMainSkillsEmpty() {
      //主技能是否为空
      return this.postInfo.mainSkills === null || this.postInfo.mainSkills.length === 0
    },
    isLastLine() {
      return index => {
        let value = document.body.clientWidth > 1440 ? 5 : 4
        return this.subSkillList.length - index < value
      }
    }
  },
  created() {
    let val = 10
    while (val < 100) {
      this.highScoreEnum.push({ value: val.toString() })
      val += 5
    }
  },
  mounted() {
    let params = this.$handleParams('postModelDetailData')
    this.getPostSkillDetail(params.postId)
    this.getMainSkillList(params.postId)
    this.postType = params.postType
    window.addEventListener('resize', this.resizeCharts)
  },
  destroyed() {
    window.removeEventListener('resize', this.resizeCharts)
  },
  methods: {
    // data
    getPostSkillDetail(postId) {
      //获取岗位, 桑基图信息
      this.postInfoLoading = true
      newPostApi
        .getPostSkillDetail(postId)
        .then(res => {
          this.postInfo = res.res
          if (!this.isMainSkillsEmpty) {
            //桑基图
            let option = {
              series: [
                {
                  type: 'sankey',
                  left: 0,
                  bottom: 0,
                  draggable: false,
                  layoutIterations: 0,
                  nodeGap: 10,
                  data: [],
                  links: [],
                  label: {
                    width: 180,
                    overflow: 'truncate'
                  },
                  emphasis: {
                    focus: 'adjacency'
                  },
                  levels: [
                    {
                      depth: 0,
                      itemStyle: {
                        color: '#FDD2CE'
                      },
                      lineStyle: {
                        color: 'source',
                        opacity: 0.6
                      }
                    },
                    {
                      depth: 1,
                      itemStyle: {
                        color: '#D1E1EE'
                      },
                      lineStyle: {
                        color: 'source',
                        opacity: 0.6
                      }
                    },
                    {
                      depth: 2,
                      itemStyle: {
                        color: '#ccebc5'
                      },
                      lineStyle: {
                        color: 'source',
                        opacity: 0.6
                      }
                    }
                  ]
                }
              ]
            }
            let links = []
            option.series[0].data = [
              { name: res.res.postName, depth: 0 },
              ...res.res.mainSkills.map(skill => {
                links.push({ source: res.res.postName, target: skill.abilityName, value: skill.rate })
                return { name: skill.abilityName, depth: 1 }
              }),
              ...[].concat(
                ...res.res.mainSkills
                  .filter(skill => {
                    return skill.subSkillNames !== null
                  })
                  .map(skill => {
                    let total = skill.subSkillNames.reduce((prev, cur) => {
                      return prev + cur.rate
                    }, 0)
                    return skill.subSkillNames.map(subSkill => {
                      links.push({
                        source: skill.abilityName,
                        target: subSkill.subSkillName,
                        value: skill.rate * (subSkill.rate / total)
                      })
                      return { name: subSkill.subSkillName, depth: 2 }
                    })
                  })
              )
            ]
            option.series[0].links = links
            this.$nextTick(() => {
              if (this.sankeyChart === null) {
                this.sankeyChart = this.$echarts.init(this.$refs.sankeyChart)
              }
              this.sankeyChart.setOption(option, true)
            })
          }
        })
        .finally(() => {
          this.postInfoLoading = false
        })
    },
    resizeCharts() {
      //重置桑基图尺寸
      if (this.sankeyChart !== null) {
        this.sankeyChart.resize()
      }
    },
    reGetPostSkillDetail(postId) {
      this.getPostSkillDetail(postId)
      this.getMainSkillInfo(this.activeMainSkillId)
    },
    reGetPostSkillInfo(postId) {
      this.getPostSkillDetail(postId)
      this.getMainSkillList(postId)
    },
    getMainSkillList(postId) {
      //获取主技能列表
      newPostApi.getMainSkillList(postId).then(res => {
        this.mainSkillList = res.res
        if (this.mainSkillList.length > 0) {
          if (this.activeMainSkillId === '') {
            this.activeMainSkillId = res.res[0].postSkillId
          }
          this.getMainSkillInfo(this.activeMainSkillId)
        }
      })
    },
    getMainSkillInfo(skillId) {
      //获取主技能详情
      newPostApi.getMainSkillInfo(skillId).then(res => {
        this.activeMainSkill = res.res
        this.rateList = res.res.skillLevels.map(item => {
          return { ...item, id: this.$generateUUID(), highScore: item.highScore.toString() }
        })
        this.getSubSkillInfo(this.activeMainSkillId)
        this.rateListForm = this.$deepCopy(this.rateList)
        this.isUpdateRateList = false
        this.$refs.skillUpdateWeightInput.closeUpdate()
        this.$refs.skillUpdateInput.closeUpdate()
        this.$refs.skillUpdateScoreInput.forEach(item => {
          if (item.isUpdate) {
            item.setClose()
          }
        })
        this.$refs.skillUpdateRateNameInput.forEach(item => {
          if (item.isUpdate) {
            item.setClose()
          }
        })
        this.$refs.skillUpdateDescriptionInput.forEach(item => {
          if (item.isUpdate) {
            item.setClose()
          }
        })
      })
    },
    getSubSkillInfo(skillId) {
      newPostApi.getSubSkillInfo(skillId).then(res => {
        this.subSkillList = res.res.map(item => {
          let courseList = []
          if (item.simpleCourses !== null) {
            courseList = item.simpleCourses
              .filter(course => {
                return course !== null
              })
              .map(course => {
                return {
                  label: course.courseName,
                  id: course.courseId,
                  children: course.chapters.map(chapter => {
                    return { label: chapter.chapterName, id: chapter.chapterId }
                  })
                }
              })
          }
          return { ...item, courseList: courseList, isActive: false }
        })
      })
    },
    //event
    updatePost(text) {
      //保存岗位信息
      let payload = {
        postName: this.postInfo.postName,
        postDescription: text,
        postId: this.postInfo.postId
      }
      newPostApi.updatePostInfo(payload).then(res => {
        if (res.res === true) {
          this.$message.success('修改专业信息成功')
          this.getPostSkillDetail(this.postInfo.postId)
        }
      })
    },
    checkSkill(skillId) {
      this.activeMainSkillId = skillId
      this.getMainSkillInfo(skillId)
    },
    toAddSkill() {
      //添加主技能
      this.$refs.mainSkillConfigDialog.open(true, this.postInfo)
    },
    updateSkill(rateList) {
      //保存技能信息
      let rateNameError = rateList.some(item => {
        return item.name === '' || this.$regSpace(item.name)
      })
      if (rateNameError) {
        this.$message.error('评分级别不能为空或全为空格')
        return
      }
      let rateDescriptionError = rateList.some(item => {
        return item.description !== '' && this.$regSpace(item.description)
      })
      if (rateDescriptionError) {
        this.$message.error('级别描述不能全为空格')
        return
      }
      let rateScoreError = rateList.some(item => {
        return item.highScore === '' || item.highScore === undefined
      })
      if (rateScoreError) {
        this.$message.error('级别分数不能为空')
        return
      }
      let payload = {
        postSkillForm: {
          ...this.activeMainSkill,
          postId: this.postInfo.postId,
          addSkillLevelForms: rateList
        },
        postSkillId: this.activeMainSkillId
      }
      newPostApi.updateMainSkillInfo(payload).then(res => {
        if (res.res === true) {
          this.$message.success('保存成功')
          this.getPostSkillDetail(this.postInfo.postId)
          this.getMainSkillInfo(this.activeMainSkillId)
          if (this.isUpdateRateList) {
            this.isUpdateRateList = false
          }
        }
      })
    },
    updateSkillDescription(text) {
      //保存技能描述
      this.activeMainSkill.description = text
      this.updateSkill(this.rateList)
    },
    updateSkillWeight(text) {
      //保存技能权重
      if (text === undefined) {
        this.$refs.skillUpdateWeightInput.setError('权重不能为空')
        return
      }
      if (text === 0) {
        this.$refs.skillUpdateWeightInput.setError('权重不能为0')
        return
      }
      this.activeMainSkill.weight = text
      this.updateSkill(this.rateList)
    },
    toDeleteSkill(data) {
      //删除主技能弹窗
      this.deleteSkillData = data
      this.$refs.postSkillDeleteDialog.open()
    },
    deletePostSkill() {
      newPostApi.deletePostSkill(this.deleteSkillData.postSkillId).then(res => {
        if (res.res === true) {
          this.activeMainSkillId = ''
          this.$message.success('删除专业技能成功')
          this.getPostSkillDetail(this.postInfo.postId)
          this.getMainSkillList(this.postInfo.postId)
        }
      })
    },
    addRate() {
      //添加一行评级
      if (this.rateList.length === 1) {
        this.$message.warning('请先修改第一行的数值')
        return
      }
      if (!this.isUpdateRateList) {
        this.toUpdateRateList()
      }
      let data = {
        id: this.$generateUUID(),
        name: this.rateListForm[this.rateListForm.length - 1].name,
        lowScore: this.rateListForm[this.rateListForm.length - 2].highScore,
        highScore: undefined,
        description: this.rateListForm[this.rateListForm.length - 1].description
      }
      this.rateList.splice(this.rateList.length - 1, 0, data)
      this.rateListForm.splice(this.rateListForm.length - 1, 0, data)
      this.$set(this.rateListForm[this.rateList.length - 1], 'name', '')
      this.$set(this.rateListForm[this.rateList.length - 1], 'description', '')
      this.$set(this.rateListForm[this.rateList.length - 1], 'lowScore', '')
      this.$nextTick(() => {
        this.$set(this.rateList[this.rateList.length - 1], 'lowScore', '')
        this.$refs.skillUpdateRateNameInput[this.rateList.length - 1].setUpdate()
        this.$refs.skillUpdateRateNameInput.forEach(item => {
          if (item.index === this.rateListForm.length - 1) {
            this.$refs.skillUpdateRateNameInput[this.rateListForm.length - 1].value = item.value
            item.value = ''
          }
        })
        this.$refs.skillUpdateDescriptionInput[this.rateList.length - 1].setUpdate()
        this.$refs.skillUpdateDescriptionInput.forEach(item => {
          if (item.index === this.rateListForm.length - 1) {
            this.$refs.skillUpdateDescriptionInput[this.rateListForm.length - 1].value = item.value
            item.value = ''
          }
        })
        this.$refs.skillUpdateScoreInput[this.rateList.length - 2].setUpdate()
      })
    },
    toUpdateRateList() {
      //激活要求评级编辑
      if (this.isUpdateRateList) {
        this.updateSkill(this.rateListForm)
        return
      }
      this.isUpdateRateList = true
      this.rateListForm = this.$deepCopy(this.rateList)
      this.$refs.skillUpdateRateNameInput.forEach(item => {
        item.setUpdate()
      })
      this.$refs.skillUpdateScoreInput.forEach(item => {
        item.setUpdate()
      })
      this.$refs.skillUpdateDescriptionInput.forEach(item => {
        item.setUpdate()
      })
    },
    closeUpdateRateName(index) {
      //重置评级名称
      this.$refs.skillUpdateRateNameInput[index].value = this.rateList[index].name
    },
    closeUpdateRateScore(index) {
      //重置比例
      this.$refs.skillUpdateScoreInput[index].value = this.rateList[index].highScore
      if (index < this.rateList.length - 1) {
        this.rateList[index + 1].lowScore = this.rateList[index].highScore
      }
    },
    closeUpdateRateDescription(index) {
      //重置评级描述
      this.$refs.skillUpdateDescriptionInput[index].value = this.rateList[index].description
    },
    deleteRate(index) {
      //删除要求
      this.rateList.splice(index, 1)
      this.rateListForm.splice(index, 1)
      if (this.rateListForm.length === 1) {
        this.$set(this.rateListForm[0], 'highScore', '100')
        this.$set(this.rateList[0], 'highScore', '100')
        this.$refs.skillUpdateScoreInput.forEach(item => {
          if (item.index === this.rateListForm.length - 1) {
            item.value = '100'
          }
        })
      } else {
        if (index === this.rateList.length) {
          this.$set(this.rateList[index - 1], 'highScore', '100')
          this.$set(this.rateListForm[index - 1], 'highScore', '100')
        } else {
          this.$set(this.rateList[index], 'lowScore', this.rateListForm[index - 1].highScore)
          this.$set(this.rateListForm[index], 'lowScore', this.rateListForm[index - 1].highScore)
        }
      }
      if (!this.isUpdateRateList) {
        this.updateSkill(this.rateListForm)
      }
    },
    changeName(text, index) {
      this.$set(this.rateListForm[index], 'name', text)
    },
    selectName(data, index) {
      this.$set(this.rateListForm[index], 'name', data.value)
    },
    computeScore(text, index) {
      if (parseInt(text) <= 0) {
        //不可以小于0,第一行重置成100,大于两行时重置成下一行的末尾
        this.$message.warning('请输入大于0的数值')
        if (index === 0 && this.rateListForm.length === 1) {
          this.$set(this.rateListForm[index], 'highScore', '100')
        } else {
          this.$set(this.rateListForm[index], 'highScore', this.rateListForm[index + 1].lowScore)
        }
        return
      } else if (parseInt(text) > 100) {
        this.$message.warning('请输入不大于100的数值')
        this.$set(this.rateListForm[index], 'highScore', '100')
        return
      } else if (index === 0 && this.rateListForm.length === 1) {
        let data = {
          id: 1,
          name: '',
          lowScore: text,
          highScore: '100',
          description: ''
        }
        this.rateListForm.push(data)
        this.rateList.push(data)
        //打开最后一行评级名称编辑
        this.$nextTick(() => {
          this.$refs.skillUpdateRateNameInput[this.rateListForm.length - 1].setUpdate()
          this.$refs.skillUpdateDescriptionInput[this.rateListForm.length - 1].setUpdate()
        })
      }
      if (parseInt(text) >= parseInt(this.rateListForm[index + 1].highScore)) {
        this.$message.warning(`请输入不大于${this.rateListForm[index + 1].highScore}的数值`)
        return
      }
      if (index !== 0 && parseInt(text) <= parseInt(this.rateListForm[index - 1].highScore)) {
        this.$message.warning(`请输入不小于${this.rateListForm[index - 1].highScore}的数值`)
        return
      }
      if (parseInt(text) < 100) {
        this.$set(this.rateListForm[index + 1], 'lowScore', text)
        this.$set(this.rateList[index + 1], 'lowScore', text)
        this.$set(this.rateListForm[index], 'highScore', text)
      }
    },
    selectScore(data, index) {
      //比例选中
      this.computeScore(data.value, index)
    },
    changeDescription(text, index) {
      //描述保存
      this.$set(this.rateListForm[index], 'description', text)
    },
    toAddSubSkill() {
      //添加子技能
      this.$refs.subSkillCUDialog.open(true, { mainSkill: { ...this.activeMainSkill }, postId: this.postInfo.postId })
    },
    toUpdateSubSkill(data) {
      //编辑子技能
      this.$refs.subSkillCUDialog.open(false, { mainSkill: { ...this.activeMainSkill }, postId: this.postInfo.postId, ...data })
    },
    toDeleteSubSkill(data) {
      //删除子技能弹窗
      this.deleteSubSkillId = data.postSkillId
      this.$refs.subSkillDeleteDialog.open()
    },
    deleteSubSkill() {
      //删除子技能
      newPostApi.deleteSubSkill({ mainSkillId: this.activeMainSkillId, subSkillId: this.deleteSubSkillId }).then(res => {
        if (res.res === true) {
          this.$message.success('删除子技能成功')
          this.reGetPostSkillDetail(this.postInfo.postId)
        }
      })
    },
    toCourseList(index) {
      //卡片翻转查看推荐课程
      if (this.subSkillList[index].isActive) {
        this.activeCard = ''
        setTimeout(() => {
          this.activeCardWrapper = ''
          this.$set(this.subSkillList[index], 'isActive', false)
        }, 600)
      } else {
        this.$set(this.subSkillList[index], 'isActive', !this.subSkillList[index].isActive)
      }
    },
    toConfigCourse(data, index) {
      this.$refs.courseConfigDialog.open(data, index)
    },
    collapseCard(index) {
      //伸缩卡片
      if (this.subSkillList[index].courseList.length === 0) {
        return
      }
      this.activeCard = this.activeCard === index ? '' : index
      if (this.activeCardWrapper === index) {
        setTimeout(() => {
          this.activeCardWrapper = ''
        }, 600)
      } else {
        this.activeCardWrapper = index
      }
    },
    toDeleteCourse(node, postSkillId, index) {
      let payload = { postSkillId: postSkillId }
      if (node.isLeaf) {
        //章节
        payload.chapterId = node.key
        payload.courseId = node.parent.key
      } else {
        //课程
        payload.courseId = node.key
      }
      newPostApi.deleteSubSkillCourse(payload).then(res => {
        if (res.res === true) {
          this.$message.success('删除推荐课程配置成功')
          if (node.isLeaf && node.parent.childNodes.length === 1) {
            this.$refs['course-tree'][index].remove(node.parent)
          } else {
            this.$refs['course-tree'][index].remove(node)
          }
        }
      })
    },
    //utils
    querySearch(queryString, cb) {
      let rates = this.rateEnum
      let results = queryString
        ? rates.filter(item => {
            return item.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0
          })
        : rates
      cb(results)
    },
    queryHighScoreSearch(queryString, cb) {
      let rates = this.highScoreEnum
      let results = queryString
        ? rates.filter(item => {
            return item.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0
          })
        : rates
      // 调用 callback 返回建议列表的数据
      cb(results)
    },
    oninputNumber(value, cb) {
      cb(value.replace(/[^0-9]/g, ''))
    },
    insertTreeNode({ index, node }) {
      this.$refs['course-tree'][index].append(node, null)
    },
    computeRate(formData, callback) {
      //计算技能权重
      let total = this.postInfo.mainSkills.reduce((prev, cur) => {
        return prev + cur.rate
      }, formData.weight)
      callback(formData.weight / total)
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/theme/postModel.less';
.yt-container {
  height: 100%;
  overflow-y: auto;
}
.description-wrapper {
  .flexStyle(flex, flex-start, flex-start);
  margin-top: 10px;
  .item {
    .flexStyle(flex, flex-start);
    width: 50%;
    ::v-deep .el-input-number {
      width: 80px;
    }
  }
}
.sankey-empty {
  .flexStyle();
  flex-direction: column;
  height: 370px;
  font-size: 16px;
}
.main-skill-list {
  overflow: hidden;
  .item {
    float: left;
    height: 32px;
    line-height: 32px;
    padding: 0 12px;
    margin-right: 2px;
    margin-bottom: 2px;
    background: #fcfcfc;
    border: 1px solid #ececec;
    border-radius: 2px 2px 0 0;
    font-size: @medium;
    cursor: pointer;
    .el-icon-error {
      display: none;
    }
    &.active {
      color: #fff;
      background-color: #448bff;
      .el-icon-error {
        display: inline-block;
      }
    }
  }
  .create-button {
    float: left;
    margin-left: 6px;
    line-height: 32px;
    svg {
      font-size: 13px;
      color: #448bff;
    }
  }
}
.el-progress {
  line-height: 8px;
}
::v-deep .el-progress-bar {
  .el-progress-bar__outer {
    height: 8px !important;
    overflow: visible;
  }
  .el-progress-bar__innerText {
    position: absolute;
    color: #000000;
    bottom: 0;
  }
}

.match-table {
  padding-bottom: 6px;
  margin-top: 20px;
  .thead {
    background: #f0f0f0;
    font-weight: bold;
  }
  .row {
    .flexStyle(flex, flex-start);
    height: 50px;
    .item {
      &.first {
        padding: 0 20px;
        width: 150px;
      }
      &.second {
        .flexStyle(flex, flex-start);
        width: 187px;
        position: relative;
        .rate {
          position: absolute;
          left: 150px;
          color: #448bff;
        }
      }
      &.third {
        flex-grow: 1;
      }
      &.tools {
        width: 48px;
        span {
          cursor: pointer;
        }
      }
      ::v-deep .el-input__inner {
        height: 38px;
        line-height: 38px;
      }
    }
  }
  .tbody {
    padding: 12px 0;
    height: 62px;
    border-bottom: 1px solid #f0f0f0;
    &:last-of-type {
      border-bottom: none;
    }
  }
  .add-button {
    .flexStyle();
    width: 100%;
    height: 38px;
    line-height: 36px;
    margin: 12px 0 8px;
    background: #ffffff;
    border: 1px dashed #d9d9d9;
    border-radius: 4px;
    color: #999999;
    cursor: pointer;
  }
  .has-error {
    ::v-deep .el-input__inner {
      border-color: #ff5050;
    }
  }
}
.sub-skill-list {
  .flexStyle(flex, flex-start);
  flex-wrap: wrap;
  margin-top: 26px;
  margin-left: -10px;
  margin-right: -20px;
  padding-left: 10px;
  li {
    height: 245px;
    margin-right: 20px;
    margin-bottom: 20px;
    border-radius: 6px;
    position: relative;
    transform-style: preserve-3d;
    transition: 0.6s all ease-in-out;
    .item {
      width: 100%;
      height: 245px;
      padding: 12px;
      background: #fcfcfc;
      border: 1px solid #ececec;
      border-radius: 6px;
      transform-style: preserve-3d;
      transition: 0.6s all ease-in-out;
      &.active {
        height: 390px;
        background-color: #fff;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
        .el-tree {
          height: 278px;
        }
      }
      &.bottom {
        position: absolute;
        bottom: 0;
      }
      &:hover {
        background-color: #fff;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
      }
    }
    &.card-active {
      z-index: 2;
    }
    &.active {
      transform: rotateY(180deg);
      .transfer-wrapper {
        height: 0;
      }
      .course-list {
        display: block;
      }
      .front {
        display: none;
      }
    }
    .transfer-wrapper {
      backface-visibility: hidden;
    }
    .top {
      .flexStyle(flex, flex-start);
      div {
        width: 30px;
        height: 30px;
        margin-right: 6px;
        background: #e6f0ff;
        border-radius: 4px;
        text-align: center;
        font-size: 20px;
        font-weight: bold;
        color: #448bff;
      }
      p {
        flex-grow: 1;
      }
      span {
        flex-shrink: 0;
        color: #999999;
        &:hover {
          cursor: pointer;
          color: #448bff;
        }
      }
    }
    .row {
      .flexStyle(flex, flex-start);
      margin-top: 12px;
      line-height: 17px;
      .row {
        margin-top: 0;
      }
      span {
        color: #999999;
      }
      &.front {
        backface-visibility: hidden;
      }
      &.button-list {
        position: absolute;
        bottom: 12px;
        left: 0;
        right: 0;
        margin: 0 auto;
        justify-content: space-between;
        font-size: 16px;
        color: #999;
        div {
          width: 50%;
          text-align: center;
        }
        .has-border {
          position: relative;
          &:after {
            content: '';
            width: 1px;
            height: 12px;
            background: #d9d9d9;
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto 0;
          }
        }
      }
    }
    .course-list {
      height: calc(100% + 12px);
      position: relative;
      transform-style: preserve-3d;
      transform: rotateY(180deg);
      display: none;
      .el-tree {
        width: calc(100% + 12px);
        height: 130px;
        margin-top: 20px;
        margin-left: -5px;
        overflow-y: auto;
        background-color: transparent;
        &::-webkit-scrollbar {
          display: none;
        }
      }
      .course-tree-node {
        .flexStyle(flex, space-between);
        width: 100%;
        color: #000000;
        .button {
          color: #999999;
          cursor: pointer;
          margin-right: 6px;
          &:hover {
            color: #448bff;
          }
        }
      }
      ::v-deep .el-icon-arrow-right {
        &.expanded {
          color: #999999;
        }
      }
      ::v-deep .el-tree-node__children {
        .el-tree-node__content {
          padding-left: 0 !important;
        }
      }
    }
  }
}
@media screen and (max-width: 1439px) {
  .sub-skill-list {
    li {
      width: calc(25% - 20px);
    }
  }
}
@media screen and (min-width: 1440px) {
  .sub-skill-list {
    li {
      width: calc(20% - 20px);
    }
  }
}
</style>
